import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface SingleImageProps {
  name: string
  alt?: string
  className?: string
  style?: React.CSSProperties
}

const SingleImage = ({ name, alt = '', className = '', style = {} }: SingleImageProps) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)|(svg)|(webp)/" } }) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                width: 1200
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)

  const image = data.allFile.edges.find(({ node }: any) => node.base === name)?.node
  const img: IGatsbyImageData | undefined = image ? getImage(image) : undefined

  return img ? <GatsbyImage image={img} className={className} alt={alt} style={style} /> : <></>
}

export default SingleImage
