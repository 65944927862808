import * as React from 'react'
import Layout from '../components/Layout'
import SEO from '@/components/seo'
import { useLocation } from '@reach/router'
import Projects from '@/components/Projects'

const Hom: React.FC<{}> = () => {
  return (
    <Layout>
      <SEO title="Projects" canonical={`${useLocation().host}`} />
      <Projects />
    </Layout>
  )
}

export default Hom
