import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Devices } from '@/hooks/useDevice'
import SingleImage from '../LazyBackgroundImage/SingleImage'

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      allProjectsJson {
        edges {
          node {
            id
            name
            image
            description
          }
        }
      }
    }
  `)

  return (
    <ProjectsArea>
      <Container className="container">
        {data.allProjectsJson.edges.map(({ node }) => (
          <ProjectWrapper key={node.id}>
            <ProjectCard>
              <ImageWrapper>
                <SingleImage name={node.image} alt="Project Image" />
              </ImageWrapper>
              <CardContent>
                <CardTitle>{node.name}</CardTitle>
                <DescriptionContainer>
                  {node.description.map((desc: string, index: number) => (
                    <Description key={`description-${index}`}>{desc}</Description>
                  ))}
                </DescriptionContainer>
              </CardContent>
            </ProjectCard>
          </ProjectWrapper>
        ))}
      </Container>
    </ProjectsArea>
  )
}

const ProjectsArea = styled.div`
  background-color: #003d51;
  padding: 40px 0px 70px 0px;

  @media ${Devices.mobile.mediaQuery} {
    padding: 20px 0px 5px 0px;
  }
`

const Container = styled.div`
  @media (min-width: 992px) and (max-width: 1024px) {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  @media ${Devices.mobile.mediaQuery} {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
`

const ProjectCard = styled.div`
  padding: 40px 50px;
  box-shadow: 10px 10px 20px 0px #00000073;
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 430px;
  max-width: 926px;
  justify-content: flex-end;
  background-color: #ffffff;
  gap: 64px;
  width: 100%;

  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 40px 30px;
  }

  @media ${Devices.mobile.mediaQuery} {
    padding: 20px;
    height: auto;
    flex-direction: column;
    gap: 0px;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-basis: 50%;
`

const ProjectWrapper = styled.div``

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  flex-basis: 50%;

  @media ${Devices.tablet.mediaQuery} {
    padding-right: 0;
  }
`

const CardTitle = styled.h3`
  font-family: BankGothic Md BT, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 41.88px;
  letter-spacing: -0.05em;
  color: #00abec;
  margin-top: 14px;
`

const DescriptionContainer = styled.div``

const Description = styled.p`
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  color: #6d6e71;
  height: auto;
  padding-right: 50px;
  margin-bottom: 20px;
`

export default Projects
