import React from 'react'
import ProjectsC from './projects'
import TopArea from './TopArea'

const Projects = () => {
  return (
    <>
      <TopArea />
      <ProjectsC />
    </>
  )
}

export default Projects
